import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import React, {useRef} from "react";
import Footer from "./Footer";
import ProjectItem from "./ProjectItem";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./../App.css";

import { FaAws, FaJenkins, FaReact, FaJava } from "react-icons/fa";
import { SiSpringboot, SiMysql, SiAnsible } from "react-icons/si";
import Box from "@mui/material/Box";
import WorkExperience from "./WorkExperience";
import Divider from "@mui/material/Divider";

import pedroalcala from "./../../src/resources/images/No_Outline_WBG.png";
import CardMedia from "@mui/material/CardMedia";
import Skills from "./Skills";
import ContactForm from "./ContactForm";

const theme = createTheme();

export default function HomePage() {



	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Container maxWidth="lg">
				<Header />
				<Box
					id="About"
					sx={{
						minHeight: 60,
						maxHeight: 60,
					}}
				></Box>
				<Typography
					sx={{
						color: "white",
						fontFamily: "IBMPlexMono-Regular",
						fontSize: 40,
						paddingTop: 20,
					}}
				>
					hello :)
				</Typography>
				<Typography
					sx={{
						color: "white",
						fontFamily: "IBMPlexMono-Thin",
						fontSize: 20,
						paddingBottom: 5,
					}}
				>
					welcome ... i'm pedro alcala 🐸
				</Typography>
				<Box
					component="img"
					sx={{
						minHeight: 288,
						maxHeight: 288,
						boxShadow: 5,
						border: 1,
						borderColor: "text.primary",
						borderRadius: 100,
						marginBottom: 30,
					}}
					src={pedroalcala}
				/>

				<Divider
					orientation="horizontal"
					sx={{ backgroundColor: "rgb(255, 255, 255)" }}
				></Divider>
				<Box
					id="Work"
					sx={{
						minHeight: 60,
						maxHeight: 60,
					}}
				></Box>
				<Typography
					sx={{
						color: "white",
						fontFamily: "IBMPlexMono-Bold",
						fontSize: 40,
						paddingBottom: 5,
					}}
				>
					WORK EXPERIENCE
				</Typography>
				<WorkExperience></WorkExperience>
				<Divider
					orientation="horizontal"
					sx={{ backgroundColor: "rgb(255, 255, 255)" }}
				></Divider>
				<Box
					id="Skills"
					sx={{
						minHeight: 60,
						maxHeight: 60,
					}}
				></Box>
				<Typography
					sx={{
						color: "white",
						fontFamily: "IBMPlexMono-Bold",
						fontSize: 40,
						paddingBottom: 5,
						paddingTop: 5,
					}}
				>
					SKILLS
				</Typography>
				<Skills></Skills>
				<Divider
					orientation="horizontal"
					sx={{
						backgroundColor: "rgb(255, 255, 255)",
						marginTop: 10,
					}}
				></Divider>
				<Box
					id="Projects"
					sx={{
						minHeight: 60,
						maxHeight: 60,
					}}
				></Box>

				<Typography
					sx={{
						color: "white",
						fontFamily: "IBMPlexMono-Bold",
						fontSize: 40,
						paddingBottom: 5,
						paddingTop: 5,
					}}
				>
					PROJECTS
				</Typography>
				<ProjectItem></ProjectItem>

				<Divider
					orientation="horizontal"
					sx={{
						backgroundColor: "rgb(255, 255, 255)",
						marginTop: 10,
					}}
				></Divider>

				<Box
					id="Contact"
					sx={{
						minHeight: 60,
						maxHeight: 60,
					}}
				></Box>

				<Typography
					sx={{
						color: "white",
						fontFamily: "IBMPlexMono-Bold",
						fontSize: 40,
						paddingBottom: 5,
						paddingTop: 5,
					}}
				>
					Contact Me
				</Typography>

				<ContactForm />

				<Footer description="" title=""></Footer>
			</Container>
		</ThemeProvider>
	);
}
