import Typography, { TypographyTypeMap } from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { workExperienceData } from "./WorkExperienceData";
import { Box, Divider } from "@mui/material";

/*interface FeaturedPostProps {
	post: {
		date: string;
		description: string;
		image: string;
		imageLabel: string;
		title: string;
		stackDescription: JSX.Element;
		stack: Array<JSX.Element>;
	};
}*/

const projectHighlight = "#98c379";

const WorkExperience = () => {
	return (
		<Grid container spacing={1} sx={{mb: 10}}>
			{workExperienceData.map((post, i, row) => (
				<Grid item xs={12} md={12}>
					<CardActionArea component="a" href="#">

						<Card sx={{border: "1px solid rgb(255, 232, 230, 0.2)", color: 'white', backgroundColor: "#1E1E24", display: { xs: "block", sm: "flex" }, textAlign: {xs: "center", sm: "left"}}} >
                        {/*<CardMedia
								sx={{
									width: '100%',
                                    maxHeight: '120px',
                                    minHeight: '120px',
									display: { xs: "flex", sm: "none" },
								}}
								component="img"
								image={post.image}
								title={post.title}
							/>
							<CardMedia
								component="img"
                                
								sx={{
                                    
									width: 160,
                                    maxHeight: '80px',
                                    minHeight: '40px',
									display: { xs: "none", sm: "block" },
                                    
								}}
								image={post.image}
								alt={post.imageLabel}
							/>*/}



							<CardContent sx={{ flex: 1 }}>
                            <Typography sx={{fontFamily: "IBMPlexMono-Regular", color: projectHighlight}} component="h1" variant="h4">
									{post.title}
								</Typography>

								<Typography sx={{fontFamily: "IBMPlexMono-Regular"}} component="h2" variant="h5">
									{post.company}
								</Typography>

 
    
								<Typography
                                    sx={{fontFamily: "IBMPlexMono-Thin", fontSize: "14px"}}
									variant="subtitle1"
									
								>
									{post.date_start + " - " + post.date_end}
								</Typography>
								<Typography >
									{post.workDescription.map((desc, i) => (
                                        <Typography variant="subtitle2" paragraph sx={{fontFamily: "IBMPlexMono-Light", fontSize: "16px"}}>{"• " + desc}</Typography>
                                    ))}
								</Typography>
							</CardContent>
						</Card>
					</CardActionArea>
                    {i + 1 === row.length ? null :  <Divider orientation="vertical" sx={{ backgroundColor: "rgb(255, 255, 255)", width: 2, height: 40, margin: 'auto', marginTop: 1}}></Divider>}
                   
				</Grid>
                
			))}
		</Grid>
	);
};

export default WorkExperience;
