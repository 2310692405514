import Typography from "@mui/material/Typography";

import ibm from "./../../src/resources/images/ibm.svg";import queerglobal from "./../../src/resources/images/queerglobal.png";

import { Divider } from "@mui/material";
import { FaAws, FaJenkins, FaReact, FaJava, FaBootstrap } from "react-icons/fa";
import {
	SiSpringboot,
	SiMysql,
	SiAnsible,
	SiMiro,
	SiGithub,
	SiGit,
	SiDocker,
} from "react-icons/si";

import { ReactComponent as GamemakerLogoSvg } from "./../../src/resources/images/gamemaker.svg";
import Icon from "@mui/material/Icon";



export const workExperienceData = [
	{
		company: "BCD Travel",
		title: "Junior Developer",
		date_start: "August 2023",
		date_end: "Current",
		/*image: ibm,*/
		imageLabel: "Image Text",
		workDescription: [
			"Proficient in integrating Sabre APIs into applications and systems.",
			"Familiarity with SOAP and RESTful API protocols for Sabre integration.",
			"Knowledge of PNR (Passenger Name Record) management within a GDS environment.",
			"Strong understanding of Global Distribution Systems (GDS) such as Sabre.",
			"Orchestrated cloud deployment of a Flask API that converts data from one format to another, allowing other developers to consume it to optimize their workflows.",
			"Enhanced internal Travel Agent Tools using QiK, reducing agent's need to manually customize itinerary outputs by providing a more robust UI and application functionality.",
			
		],
	},
	{
		company: "IBM",
		title: "Software Engineer Apprentice",
		date_start: "January 2022",
		date_end: "January 2023",
		/*image: ibm,*/
		imageLabel: "Image Text",
		workDescription: [
			"Performed manual and automated validations with the development and QA team in an Agile environment to check the health of micro-services in staging and production, increasing testing and deployment speed.",
			"Involved in the SDLC of new security features using languages such as Java, and the automation of tasks and testing with Robot Framework and Python.",
			"Analyzed logs with tools such as Kibana and Elastic to identify and report issues during or after testing, reducing the number of possible issues in production environments.",
			"Obtained and completed training in Computer Science fundamentals, CI/CD, Automation Suites, RESTful API’s, Technical Debt, Containers (Docker) and Orchestration (Kubernetes), Programming Languages (Java, Python, JavaScript), Agile and Scrum Methodologies."
		],
	},
	{
		company: "QueerGlobal LLC",
		title: "Front-end Developer",
		date_start: "Oct 2020",
		date_end: "Jul 2021",
		/*image: ibm,*/
		imageLabel: "Image Text",
		workDescription: [
			"Assemble Queer's Global website layout and organization from scratch working side-by-side with the engineering and development team.",
			"Created features to enhance user experience and ensure optimal browsing by using React, JavaScript, HTML, and CSS.",
			"Translated UX designs and project ideas into tangible working components.",
			"Built reusable components in React to reduce maintenance, speed up development, and provide scalability.",
			"Taught new Junior Developers and team members the structure and methodology used in the code, including the creation of video tutorials for future reference and guidance.",
			"Worked with GIT Version Control and JIRA board for SCRUM/AGILE."
		],
	}
];
