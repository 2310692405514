import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Grid, TextField } from "@mui/material";

export default function ContactForm() {
	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<form
				action="https://formspree.io/f/mjvdojyv"
				method="POST"
				style={{ width: "100%" }}
			>
				<Grid item xs={12} md={12}>
					<TextField
						required
						variant="outlined"
						id="outlined-required"
						label="Email"
						defaultValue=""
						name="email"
						type="email"
						fullWidth
						inputProps={{
							style: { color: "white", width: "100%" },
						}}
						InputLabelProps={{ style: { color: "white" } }}
						sx={{
							"&:hover fieldset": {
								border: "2px solid white!important",
								borderRadius: 1,
							},
							"&:focus-within fieldset, &:focus-visible fieldset":
								{
									border: "4px solid white!important",
								},
							marginBottom: 5,
							fieldset: { borderColor: "white" },
						}}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<TextField
						required
						id="outlined-required"
						label="Message"
						defaultValue=""
						name="message"
						type="text"
						multiline
						fullWidth
						rows={10}
						inputProps={{
							style: { color: "white", width: "100%" },
						}}
						InputLabelProps={{ style: { color: "white" } }}
						sx={{
							"&:hover fieldset": {
								border: "2px solid white!important",
								borderRadius: 1,
							},
							"&:focus-within fieldset, &:focus-visible fieldset":
								{
									border: "4px solid white!important",
								},
							marginBottom: 5,
							fieldset: { borderColor: "white" },
						}}
					/>
				</Grid>

				<Button
					variant="contained"
					type="submit"
					sx={{
						backgroundColor: "#98c379",
						color: "#1E1E24",
						fontFamily: "IBMPlexMono-Bold",
						fontSize: 25,
						"&:hover": {
							backgroundColor: "#618745",
							color: "#1E1E24",
						},
					}}
				>
					Send
				</Button>
			</form>
		</Grid>
	);
}
