import Typography from "@mui/material/Typography";

import blobs from "./../../src/resources/images/blobs.png";
import doggobrick from "./../../src/resources/images/doggobrick.png";
import comichq from "./../../src/resources/images/comichq.png";
import tictactoe from "./../../src/resources/images/tictactoe.png";
import { Divider } from "@mui/material";
import { FaAws, FaJenkins, FaReact, FaJava, FaBootstrap } from "react-icons/fa";
import {
	SiSpringboot,
	SiMysql,
	SiAnsible,
	SiMiro,
	SiGithub,
	SiGit,
	SiDocker,
} from "react-icons/si";

import { ReactComponent as GamemakerLogoSvg } from "./../../src/resources/images/gamemaker.svg";
import Icon from "@mui/material/Icon";


const projectHighlight = "#98c379";
const projectWarning = "#E26E7D";

export const featuredProjects = [
	/*{	
		title: "ComicHQ - WIP",
		date: "Nov 12",
		description: "Comic Reservation Application - CRUD Application.",
		image: comichq,
		imageLabel: "Image Text",
		url: "https://comichq.pedroalcala.com/",
		warning: (<span style={{ color: projectWarning }}>
			NOTE: This website Backend will only work when requested to keep AWS services cost down.
		</span>),
		stackDescription: (
			<Typography
				component="span"
				sx={{
					fontFamily: "IBMPlexMono-Regular",
				}}
			>				
				Uses several AWS services such as{" "}
				<span style={{ color: projectHighlight }}>Cognito</span>,{" "}
				<span style={{ color: projectHighlight }}>Route53</span>,{" "}
				<span style={{ color: projectHighlight }}>ACM</span>,{" "}
				<span style={{ color: projectHighlight }}>SES</span>,{" "}
				<span style={{ color: projectHighlight }}>Amplify</span>,{" "}
				<span style={{ color: projectHighlight }}>EC2</span>,{" "}
				<span style={{ color: projectHighlight }}>WorkMail</span>,{" "}
				<span style={{ color: projectHighlight }}>RDS</span>.
				<Divider
					sx={{ backgroundColor: "rgb(255, 232, 230)" }}
				></Divider>
				Backend done in{" "}
				<span style={{ color: projectHighlight }}>Java</span> and{" "}
				<span style={{ color: projectHighlight }}>SpringBoot</span>.
				<Divider
					sx={{ backgroundColor: "#1E1E24", border: "0px" }}
				></Divider>
				Frontend done in{" "}
				<span style={{ color: projectHighlight }}>React</span> and{" "}
				<span style={{ color: projectHighlight }}>Bootstrap</span>.
			</Typography>
		),
		stack: [
			<FaAws />,
			<FaJenkins />,
			<FaJava />,
			<FaReact />,
			<SiSpringboot />,
			<SiMysql />,
			<SiAnsible />,
			<SiGit />,
			<SiGithub />,
			<SiDocker />,
			<FaBootstrap />
		],
	},*/
	{
		title: "TicTacToe",
		date: "Nov 12",
		description:
			"Interactive Game Application for users to play the classic Tic Tac Toe in their browser.",
		image: tictactoe,
		imageLabel: "Image Text",
		url: "https://tictactoe.pedroalcala.com/",
		warning: (<></>),
		stackDescription: (
			<Typography
				component="span"
				sx={{
					fontFamily: "IBMPlexMono-Regular",
				}}
			>
				Uses {" "}
				<span style={{ color: projectHighlight }}>AWS Amplify</span> for
				CI/CD, and{" "}
				<span style={{ color: projectHighlight }}>Git/GitHub</span> for
				version control and collaboration.
				<Divider
					sx={{ backgroundColor: "rgb(255, 232, 230)" }}
				></Divider>
				Frontend done in{" "}
				<span style={{ color: projectHighlight }}>React</span> and{" "}
				<span style={{ color: projectHighlight }}>Bootstrap</span>.
			</Typography>
		),
		stack: [
			<FaAws />,
			<FaReact />,
			<SiGit />,
			<SiGithub />,
			<FaBootstrap />
		],
	},
	{
		title: "DOGGOBRICK",
		date: "Nov 11",
		description:
			"DOGGOBRICK is a game about helping your little doggo from being destroyed by some terrible bricks! Just a toy project created to see how fast I could code a game in a short amount of time.",
		image: doggobrick,
		imageLabel: "Image Text",
		url: "https://fourzer0.itch.io/doggobrick",
		warning: (<></>),
		stackDescription: (
			<Typography
				component="span"
				sx={{
					fontFamily: "IBMPlexMono-Regular",
				}}
			>
				Developed in one day using{" "}
				<span style={{ color: projectHighlight }}>
					GameMaker Studio 1.4
				</span>
			</Typography>
		),
		stack: [<Icon component={GamemakerLogoSvg}></Icon>],
	},
	{
		title: "BLOBS",
		date: "Nov 11",
		description:
			"BLOBS is a game made for the GMTK Jam 2021. It's a 2D puzzle platformer that uses the theme of the jam, joined together, to create both advantages or disadvantages for the player in a series of fun challenges of skill.",
		image: blobs,
		imageLabel: "Image Text",
		url: "https://howlevans.itch.io/blobs",
		warning: (<></>),
		stackDescription: (
			<Typography
				component="span"
				sx={{
					fontFamily: "IBMPlexMono-Regular",
				}}
			>
				Developed in two days using{" "}
				<span style={{ color: projectHighlight }}>
					GameMaker Studio 1.4
				</span>
				, using tools such as{" "}
				<span style={{ color: projectHighlight }}>Git</span>,{" "}
				<span style={{ color: projectHighlight }}>MIRO</span> and{" "}
				<span style={{ color: projectHighlight }}>GitHub</span> for
				collaboration.{" "}
			</Typography>
		),
		stack: [
			<SiGit />,
			<SiGithub />,
			<SiMiro />,
			<Icon component={GamemakerLogoSvg}></Icon>,
		],
	},
];
