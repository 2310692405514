import Typography from "@mui/material/Typography";

import blobs from "./../../src/resources/images/blobs.png";
import doggobrick from "./../../src/resources/images/doggobrick.png";
import comichq from "./../../src/resources/images/comichq.png";
import tictactoe from "./../../src/resources/images/tictactoe.png";
import { Divider } from "@mui/material";
import { FaAws, FaJenkins, FaReact, FaJava, FaBootstrap } from "react-icons/fa";
import {
	SiSpringboot,
	SiMysql,
	SiAnsible,
	SiGithub,
	SiGit,
	SiDocker,
	SiHtml5,
	SiCss3,
	SiJavascript,
	SiGodotengine,
	SiKubernetes,
	SiPython,
} from "react-icons/si";

import { ReactComponent as GamemakerLogoSvg } from "./../../src/resources/images/gamemaker.svg";
import Icon from "@mui/material/Icon";


const projectHighlight = "#98c379";
const projectWarning = "#E26E7D";

export const skills = [
	{
		title: "Front-end Developer",
		description:
			"I'm a frontend developer who uses HTML, CSS, and JavaScript, along with frameworks like React to create user-friendly interfaces for websites and applications. My goal is to design responsive and visually appealing sites that offer the best possible user experience.",
		stack: [
			<SiHtml5 />,
			<SiCss3 />,
			<SiJavascript />,
			<FaReact />,
			<FaBootstrap />,
		],
	},
	{
		title: "Software Engineering",
		description:
			"I'm a software engineer who develops applications using Python, CI/CD tools, and Cloud services. My expertise in data structures and algorithms helps me write efficient code, and I'm proficient in AWS services like EC2, S3, and Lambda. I automate the software development lifecycle using CI/CD tools like Jenkins and stay current with the latest technologies and best practices.",
		stack: [
			<FaAws />,
			<SiPython />,
			<SiGit />,
			<SiGithub />,
			<SiSpringboot />,
			<SiMysql />,
			<SiAnsible />,
			<SiDocker />,
			<SiKubernetes />,
			<FaJava />,
			<FaJenkins />,
		],
	},
	{
		title: "Game Development",
		description:
			"I'm a game developer who specializes in creating video games using Game Maker Studio and Godot. My role involves designing and programming game mechanics, characters, and environments, as well as integrating visual and audio elements. With these tools, I can create games for multiple platforms, from desktop to mobile and console.",
		stack: [<Icon component={GamemakerLogoSvg}></Icon>, <SiGodotengine />],
	},
];
