
import Typography, { TypographyTypeMap } from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { featuredProjects } from "./ProjectItemsData";
import { Divider } from "@mui/material";

/*interface FeaturedPostProps {
	post: {
		date: string;
		description: string;
		image: string;
		imageLabel: string;
		title: string;
		stackDescription: JSX.Element;
		stack: Array<JSX.Element>;
	};
}*/

const ProjectItem = (() => {
	return (
		<Grid container spacing={1}>
			{featuredProjects.map((project, i) => (
				<Grid item xs={12} md={6} key={i}>
					<CardActionArea 
						component="a"
						href={project.url}
						target="_blank"
						sx={{ border: "1px solid rgb(255, 232, 230, 0.2)"}}
					>
						<Card  sx={{width: { xs: "160", sm: "360" } }}>
							<CardMedia
								sx={{  minHeight: 400, maxHeight: 400}}
								component="img"
								image={project.image}
								title={project.title}
							/>
							<Divider
								sx={{ backgroundColor: "rgb(255, 232, 230)" }}
							></Divider>

							<CardContent style={{ backgroundColor: "#1E1E24", minHeight: 400}} >
								<Typography
									gutterBottom
									component="div"
									sx={{
										color: "#98c379",
										fontFamily: "IBMPlexMono-Light",
									}}
								>
									{project.title}
								</Typography>
								<Typography
									sx={{
										m: 1,
										color: "rgb(255, 232, 230)",
										fontFamily: "IBMPlexMono-Regular",
									}}
								>
									{project.description}
								</Typography>

								<Typography
									gutterBottom
									component="div"
									sx={{
										color: "#98c379",
										fontFamily: "IBMPlexMono-Light",
									}}
								>
									{project.warning}
								</Typography>

								<Divider
									sx={{
										backgroundColor: "rgb(255, 232, 230)",
									}}
								></Divider>

								<Typography
									sx={{
										m: 1,
										color: "rgb(255, 232, 230)",
										fontFamily: "IBMPlexMono-Regular",
									}}
								>
									{project.stackDescription}
								</Typography>

								<Divider
									sx={{
										backgroundColor: "rgb(255, 232, 230)",
									}}
								></Divider>

								{project.stack.map((techStack, i) => (
									<IconButton
										key={i}
										size="large"
										sx={{ color: "rgb(255, 232, 230)" }}
									>
										{techStack}
									</IconButton>
								))}
							</CardContent>
						</Card>
					</CardActionArea>
				</Grid>
			))}
		</Grid>
	);
})

export default ProjectItem;