import React, {useRef} from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import { AppBar, Box, Divider, Grid, SwipeableDrawer } from "@mui/material";
import { useState } from "react";

import {Link as LinkScroll} from 'react-scroll'

export default function Header(props: any) {

	

	const navItems = [
		{ title: "About Me", url: "About" },
		{ title: "Work Experience", url: "Work" },
		{ title: "Skills", url: "Skills" },
		{ title: "Projects", url: "Projects" },
		{ title: "Contact Me", url: "Contact" },
	];


	const [open, setOpen] = useState(false);

	return (
		<React.Fragment>
			<Container maxWidth="lg" sx={{ backgroundColor: "#1E1E24" }}>
				<AppBar
					position="fixed"
					style={{
						background: "#1E1E24",
					}}
				>
					<Toolbar
						component="nav"
						variant="regular"
						sx={{
							justifyContent: "space-evenly",
							overflowX: "auto",
							alignItems: "center",
						}}
					>
						<Hidden smDown>
							{navItems.map((item) => (
								<Link
									underline="hover"
									color="inherit"
									noWrap
									key={item.title}
									sx={{ color: "white", cursor: "pointer" }}
								>
									<LinkScroll
										to={item.url}
										spy={true}
										smooth={true}
									>
										{item.title}
									</LinkScroll>
								</Link>
							))}
						</Hidden>

						<Hidden smUp>
							<IconButton
								sx={{
									color: "#FFFFFF",
									verticalAlign: "middle",
									margin: "auto",
									alignItems: "center",
									justifyContent: "center",
								}}
								onClick={() => setOpen(true)}
							>
								<MenuIcon
									fontSize="inherit"
									sx={{
										color: "#FFFFFF",
									}}
								></MenuIcon>
							</IconButton>
						</Hidden>
					</Toolbar>
				</AppBar>
			</Container>
			<SwipeableDrawer
				anchor="top"
				open={open}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			>
				{navItems.map((item) => (
					<Link
						underline="hover"
						color="inherit"
						noWrap
						key={item.title}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							color: "white",
							fontFamily: "IBMPlexMono-Regular",
							backgroundColor: "#1E1E24",
							paddingBottom: "10px",
							paddingTop: "10px",
						}}
					>
						<LinkScroll
							to={item.url}
							spy={true}
							smooth={true}
							onClick={() => setOpen(false)}
						>
							{item.title}
						</LinkScroll>
					</Link>
				))}
			</SwipeableDrawer>
		</React.Fragment>
	);
}
